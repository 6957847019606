<template>
	<div>
		<el-col :span="1.5" class="elrow">
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="searchByInfo">查询</el-button>
			</el-form-item>
		</el-col>
		<el-col :span="1.5" class="elrow">
			<el-form-item>
				<el-button type="primary" icon="el-icon-refresh" size="mini" @click="clearAll">重置</el-button>
			</el-form-item>
		</el-col>
        <!-- <el-col :span="1.5" class="elrow">
			<el-form-item>
				<el-button type="primary" icon="el-icon-upload" size="mini">导入</el-button>
			</el-form-item>
		</el-col> -->
		<el-col :span="1.5" class="elrow">
			<el-form-item>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="exportEvent">下载</el-button>
			</el-form-item>
		</el-col>
	</div>
</template>

<script>
	export default {
		props: {
			showAll: {
				type: Boolean,
				defalut: true
			},
		},
		computed: {
			word: function() {
				if (this.showAll == true) {
					//对文字进行处理
					return "展开搜索";
				} else {
					return "收起搜索";
				}
			}
		},
		mounted() {
			/**
			 * 收起搜索
			 */
			this.$nextTick(function() {
				this.closeSearch();
			});
		},
		methods: {
			closeSearch() {
				//					this.showAll = !this.showAll;
				this.$emit('closepop');
				console.log('子组件的状态：' + this.showAll)
			},
			searchByInfo(){
				this.$emit('searchEvent')
			},
			clearAll(){
               this.$emit('clearAll')
			},
			exportEvent(){
               this.$emit('exportEvent')
			}
		}
	}
</script>

<style lang="scss" scoped>
  /deep/ .el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid rgba(96, 16, 128, 0.5);
    border-radius: 4px;
    background-color: rgba(96, 16, 128, 0.5);
    box-sizing: border-box;
    margin: 5px 0;
  }

  //修改总体选项的样式 最外层
  /deep/ .el-select-dropdown {
    background-color: #08164d;
    margin: 0px;
    border: 0px;
    border-radius: 0px;
  }

  //修改单个的选项的样式
  /deep/ .el-select-dropdown__item {
    background-color: transparent;
    color: #fff;
  }

  //item选项的hover样式
  /deep/ .el-select-dropdown__item:hover {
    color: #409eff;
  }
   //修改总体选项的样式 最外层
  /deep/ .el-select-dropdown {
    background-color: #08164d;
    margin: 0px;
    border: 0px;
    border-radius: 0px;
  }

  //修改单个的选项的样式
  /deep/ .el-select-dropdown__item {
    background-color: transparent;
    color: #fff;
  }

  //item选项的hover样式
  /deep/ .el-select-dropdown__item:hover {
    color: #409eff;
  }
</style>

