<template>
  <!-- 权限管理页面 -->
  <div>
    <div class="table-layout">
      <el-row :gutter="20">
        <el-form
          ref="authForm"
          :model="authForm"
          label-width="90px"
          :inline="true"
        >
          <el-row class="btnRow">
            <el-col :span="5" class="elrow">
              <el-form-item
                label="唯一编码"
                prop="iditifyId"
                style="padding-left: 10px"
              >
                <el-input
                  v-model="authForm.iditifyId"
                  size="mini"
                  clearable
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="elrow">
              <el-form-item label="在线状态" prop="xsxz">
                <el-select
                  v-model="authForm.status"
                  size="mini"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in onlineOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <search-button
              v-show="showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></search-button>
            <SearchButton
              v-show="!showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></SearchButton>
          </el-row>
        </el-form>
      </el-row>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- 新增按钮区域 -->
      <el-row style="margin-bottom: 10px">
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-plus"
            size="mini"
            class="editBtn"
            @click="add"
            >新增用户</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-refresh"
            size="mini"
            class="editBtn"
            @click="editSensor"
            >修改</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            class="deleteBtn"
            icon="el-icon-delete"
            size="mini"
            @click="delSensor"
            >删除</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="bangdingSensor"
              >授权角色</el-button
            >
          </el-col>
      </el-row>
      <!-- 权限数据表格 -->
      <el-table
        :data="roleTable"
        style="width: 100%"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChangeNode"
        border
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index }} </template>
        </el-table-column>
        <el-table-column prop="username" label="账号"> </el-table-column>
        <el-table-column prop="user_id" label="id" v-if="false"> </el-table-column>
        <el-table-column prop="establish_time" label="备注"> </el-table-column>
        <el-table-column prop="role" label="角色"> </el-table-column>
      </el-table>
    </div>
    <!-- 4、新增、修改弹窗区域-->
    <!--新增单条角色信息管理-->
    <!-- <div>
      <el-dialog
        title=""
        :append-to-body="true"
        :visible.sync="singleAddDialogVisible"
        custom-class="custom-dialog"
        width="30%"
        center
      >
        <div class="openDigl">
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="100px"
              :model="authForm"
            >
              <el-form-item label="路由id:" :required="true" v-if="false">
                <el-input
                  class="btn-text"
                  v-model="authForm.username"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="路由名称:" :required="true">
                <el-input class="btn-text" v-model="authForm.role"></el-input>
              </el-form-item>
              <el-form-item label="说明:" :required="true">
                <el-input class="btn-text" v-model="authForm.explain"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn-text"
                  @click="handleClick"
                  >{{ addorEdittext }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div> -->
    <!--授权角色弹窗-->
    <div>
      <el-dialog
        title=""
        :append-to-body="true"
        :visible.sync="singleAddDialogVisible"
        custom-class="custom-dialog"
        width="30%"
        center
      >
        <div class="openDigl">
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="100px"
              :model="authForm"
            >
              <el-form-item label="用户名:" :required="true">
                <el-input
                  class="btn-text"
                  v-model="authForm.username"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码:" :required="true">
                <el-input class="btn-text" type="password" v-model="authForm.password1"></el-input>
              </el-form-item>
              <el-form-item label="确认密码:" :required="true">
                <el-input class="btn-text"  type="password" v-model="authForm.password2"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn-text"
                  @click="handleClick"
                  >{{ addorEdittext }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
    <!--授权用户所属的角色-->
    <div>
      <el-dialog
        title="授权权限"
        :append-to-body="true"
        :visible.sync="shouquanDialogVisiable"
        custom-class="custom-dialog"
        width="40%"
        center
      >
        <el-form>
          <el-form-item>
            <el-tree
              node-key="role_id"
              ref="tree"
              :props="props"
              :default-checked-keys="defaultKey"
              :data="menuList"
              show-checkbox
               @check-change="handleCheckChange"
            ></el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-text" @click="shouquanEvent">{{
              addorEditshoquan
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { userSearchAll, userAdd, userEdit, userDel, showRelation, addUserRole,  editUserRole, getAllRoles } from "@/api/index";
import SearchButton from "./tool/button.vue";
/* 引入菜单管理api接口 */

export default {
  name: "SysMenu",
  components: {
    SearchButton,
  },
  data() {
    return {
      roleTable: [
        { username: "Lanzhi_2024_manage", role: "超级管理员", explain: "superAdmin", user_id: "2"},
        { username: "Device_manage_guiyang", role: "茶店变管理", explain: "admin", user_id: "3"},
        { username: "XiaoGuanBian_user", role: "普通用户", explain: "user", user_id: "4"},
        { username: "ChaDianBian_user", role: "普通用户", explain: "user", user_id: "5"},
        { username: "User_001", role: "普通用户", explain: "user", user_id: "6"}
      ],
      /* 弹窗区域字段 */
      dialogVisible: false,
      dialogTitle: "",
      // 控制菜单类型单选按钮是否可用
      typeDisabled: false,
      typeDisabled0: false,
      typeDisabled1: false,
      typeDisabled2: false,
      // 控制弹窗确定按钮是否可用
      saveBtnDisabled: false,
      singleAddDialogVisible: false,
      shouquanDialogVisiable: false,
      addorEditshoquan: "授权",
      addorEdittext: "新增",
      selctionNodeRows: [],
      nodeids: [],
      showAll: true,
      //查询条件form
      authForm: {
        username: "",
        role: "",
        explain: "",
        password1: "",
        password2: ""
      },
      menuList: [],
      defaultKey: [],
      props: {
          label: 'role',
          children: 'zones'
        },
      onlineOptions: [
        { label: "userAdmin", value: "1" },
        { label: "admin", value: "2" },
        { label: "user", value: "3" },
      ],
    };
  },
  methods: {
    /* 获取用户 */
    getRoleList() {
       userSearchAll().then(res=>{
      if (res.code == 200) {
        this.roleTable = res.data;
      } else {
        this.$message.error(res.error);
      }
       }).catch(err => {
        console.log(err)
       })
    },
    add() {
      // 新增用户弹窗信息
      this.authForm = {};
      this.singleAddDialogVisible = true;
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 新增传感器信息
    addSensor() {
      userAdd(this.authForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getRoleList();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        console.log(this.selctionNodeRows[0])
        this.authForm.username = this.selctionNodeRows[0].username;
        this.authForm.user_id = this.selctionNodeRows[0].user_id;
        this.authForm.role = this.selctionNodeRows[0].role;
        this.authForm.password1 = this.selctionNodeRows[0].password
        this.authForm.password2 = this.selctionNodeRows[0].password
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个传感器
    delSensor() {
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        userDel({ user_id: this.selctionNodeRows[0].user_id })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getRoleList();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改变电站
    relationEdit() {
      // 使用链接put 修改
      let params = {
        username: this.authForm.username,
        user_id: this.authForm.user_id,
        password1: this.authForm.password1,
        password2: this.authForm.password2
      };
      userEdit(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getRoleList();
        }
      });
    },
    closepop() {
      this.showAll = !this.showAll;
    },
    // 绑定传感器和设备
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
      // 加载所有角色列表
      getAllRoles().then(res=>{
        this.menuList = res.data
      })
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        // 判断一下，如果有过授权的话，那么就改成修改授权；如果没有授权的话，还是授权；通过查询授权结果得到的结果不为空
        showRelation(this.selctionNodeRows[0].user_id)
          .then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            res.data.forEach(v=>{
              this.defaultKey.push(v.role_id)
            })
            this.shouquanDialogVisiable = true;
            this.addorEditshoquan = "修改授权";
            // 如果有可能的话，这块需要把授权赋值过去，以便用户修改
          } else {
            this.shouquanDialogVisiable = true;
            this.addorEditshoquan = "授权";
            // 这里就是加载所有权限列表，以供用户在列表中单选
          }
        }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    handleCheckChange(data, checked, indeterminate){
      console.log(data, checked, indeterminate);
    },
    // 授权事件
    shouquanEvent() {
      // 授权事件
      if (this.addorEditshoquan == "授权") {
        this.addOneAuth();
      } else {
        this.editOneAuth();
      }
    },
    // 新增授权
    addOneAuth() {
      let params = {
        user_id: this.selctionNodeRows[0].user_id,
        role_ids: []
      }
      this.$refs.tree.getCheckedNodes().forEach(v=>{
         params.role_ids.push(v.role_id)
      })
      addUserRole(params).then(res=>{
        if(res.code == 200){
          this.$message.success("授权成功！")
        } else {
          this.$message.error("授权失败！原因是"+ res.error)
        }
        this.shouquanDialogVisiable = false
        this.getRoleList()
      })
      
    },
    editOneAuth() {
      let params = {
        user_id: this.selctionNodeRows[0].user_id,
        role_ids: []
      }
      this.$refs.tree.getCheckedNodes().forEach(v=>{
         params.role_ids.push(v.role_id)
      })
      editUserRole(params).then(res=>{
        if(res.code == 200){
          this.$message.success("授权成功！")
        } else {
          this.$message.error("授权失败！原因是"+ res.error)
        }
        this.shouquanDialogVisiable = false
        this.getRoleList()
      })
    },
    searchSenser() {
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.authForm);
    },
    // 重置
    clearAll() {},
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
  //当页面加载时获取数据
  created() {
    // 获取所有角色
    this.getRoleList();
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.dialog-item {
  background-color: rgba(27, 42, 65, 0.8);
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
::v-deep .el-tree {
  position: relative;
  cursor: pointer;
  background: transparent;
  color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background: #6f9ec9;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgb(20, 107, 107) !important;
}
</style>